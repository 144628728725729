import React from 'react';
import App from 'next/app';
import bugsnagClient from '~/util/bugsnag';
import Error from '~/pages/_error';

declare var BRANCH_KEY: string;

const ErrorBoundary = bugsnagClient.getPlugin('react').createErrorBoundary(React);

export default class MyApp extends App {
  async componentDidMount() {
    const BranchSDK = (await import('branch-sdk')).default;
    BranchSDK.init(BRANCH_KEY);

    BranchSDK.data(err => {
      if (err) {
        console.warn(`Branch failed to resolve link: ${err}`);
      }
    });
  }

  render() {
    const { Component, pageProps } = this.props;

    return (
      <ErrorBoundary FallbackComponent={Error}>
        <Component {...pageProps} />
      </ErrorBoundary>
    );
  }
}
